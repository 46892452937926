// 我的保养组件
import { useSignal } from "@preact/signals"
import { useEffect, useRef } from "preact/hooks"
import { ECharts, echarts } from "../deps.ts"
import { mock } from "../static/libs/echarts/mock_data.ts"
import themeState from "../utils/theme.ts"
import { DateCount, HourCount } from "../dao/types.ts"

enum ChartType {
  Today = "今日",
  Days7 = "近 7 日",
  Days30 = "近 30 日",
}
const types = Object.values(ChartType)

export default function StatChart({ chartClass }: { chartClass?: string }) {
  const chartRef = useRef<HTMLDivElement>(null) // 图表 dom
  const chartType = useSignal(ChartType.Today) // 图表类型

  let chart: ECharts | null
  const onWinResize = () => chart?.resize()

  // 绘制图表
  // deno-lint-ignore no-explicit-any
  function renderChart(options: any) {
    chart?.dispose()
    chart = echarts.init(chartRef.current, themeState.value.dark ? "dark" : null, { renderer: "canvas" })
    // console.log(JSON.stringify(options))
    chart.setOption(options)
    // chart.setOption(mock[chartType.value].options) // 模拟数据
  }

  // 绘制图表
  useEffect(() => {
    if (!chartRef.current) return

    // 监听浏览器窗口大小变动事件，重新绘制图表
    addEventListener("resize", onWinResize)

    // 加载图表数据
    const tooltipBgColor = themeState.value.dark ? "rgba(0, 0, 0, 0.5)" : "rgba(255, 255, 255, 0.5)"
    if (chartType.value === ChartType.Today) {
      fetch("/api/repair/today", { credentials: "include" })
        .then((rep) => rep.ok ? rep.json() : Promise.reject(new Error(`${rep.status} ${rep.statusText}`)))
        .then((data: HourCount[]) =>
          renderChart({
            tooltip: { backgroundColor: tooltipBgColor },
            xAxis: { data: data.map(({ hour }) => hour), axisLabel: { rotate: -90 } },
            yAxis: {},
            series: [{ type: "bar", data: data.map(({ count }) => count) }],
          })
        )
    } else {
      const n = chartType.value === ChartType.Days7 ? 7 : 30
      fetch(`/api/repair/lastnday?n=${n}`, { credentials: "include" })
        .then((rep) => rep.ok ? rep.json() : Promise.reject(new Error(`${rep.status} ${rep.statusText}`)))
        .then((data: DateCount[]) =>
          renderChart({
            tooltip: { backgroundColor: tooltipBgColor },
            xAxis: {
              data: data.map(({ date }) => date.substring(6).replace("-", "/")),
              axisLabel: { rotate: chartType.value === ChartType.Days30 ? -90 : 0 },
            },
            yAxis: {},
            series: [{ type: "bar", data: data.map(({ count }) => count) }],
          })
        )
    }

    return () => {
      chart?.dispose()
      removeEventListener("resize", onWinResize)
    }
  }, [chartType.value, themeState.value])

  return (
    <>
      <div class="flex place-content-center">
        <div class="flex flex-col">
          <div class="text-center text-lg">已保养车数统计</div>
          <form>
            {types.map((type) => (
              <label class="m-2">
                <input
                  type="radio"
                  name="chartType"
                  class="checked:bg-blue-500"
                  value={type}
                  checked={chartType.value === type}
                  onChange={(e) => chartType.value = e.target?.value as ChartType}
                />
                <span class="pl-1">{type}</span>
              </label>
            ))}
          </form>
        </div>
      </div>
      <div ref={chartRef} class={chartClass ?? "min-h-[10rem]"}>
      </div>
    </>
  )
}
